import Vue from "vue";
import Vuex from "vuex";
import { BreadcrumbItem } from "@/models/breadcrumbs";
import { ConfigurationModel } from "@/models/configurationModel";
import GlobalHelper from "@/helpers/globalHelper";
import ErrorHelper from "@/helpers/errorHelper";
import { Company } from "common-vue-components/models/company";
import router from "@/router";
import RouterHelper from "@/helpers/routerHelper";
import config from "@/config";
import { configurationService, companiesService } from "@/services";
import { CommonText } from "@/constants";
import { ResponseTypes } from "@/helpers/enums";

const currentLocationPath = (state: any) => {
  return state.currentLocation
    .filter((_: any, index: number) => index > 0)
    .map((item: any) => item.text)
    .join("/");
};

class AppState {
  allowReload = true;
  drawerState = false;
  contactDialogState = false;
  infoDialogState = false;
  areCompaniesLoading = true;
  activeCompany: Company | null = null;
  companies: Array<Company> = [];
  currentLocation = [new BreadcrumbItem("", false, null, null, "")];
  queueData: any = null;
  activityAutoRefresh = false;
  repoGridAutoRefresh = true;
  failedOperations = false;
  statusDialogState = false;
  statusDialogInfo = 0;
  configurationDialogState = false;
  configurationInfo = ConfigurationModel.FromObject({});
  selectedFolders: any = [null];
  validatePdfs = false;
  highlightedItem: any = null;
  canAccessConfiguration = false;
  hasAccess = true;
};

Vue.use(Vuex);

export default new Vuex.Store({
  state: new AppState(),
  mutations: {
    changeAllowReload: (state, allowReload): void => {
      state.allowReload = allowReload;
    },
    changeValidatePdfs: (state, validatePdfs): void => {
      state.validatePdfs = validatePdfs;
    },
    changeDrawerState: (state): void => {
      state.drawerState = !state.drawerState;
    },
    changeContactDialogState: (state): void => {
      state.contactDialogState = !state.contactDialogState;
    },
    changeInfoDialogState: (state): void => {
      state.infoDialogState = !state.infoDialogState;
    },
    addNewLocation: (state, newLocation): void => {
      let path = currentLocationPath(state);
      path = path ? `${path}/` : "";
      state.currentLocation.push(
        new BreadcrumbItem(newLocation.Name, false, newLocation, state.currentLocation[state.currentLocation.length - 1],
          `#${path}${newLocation.Name}`));
    },
    changeLocationPlacement: (state, locationSet): void => {
      if (locationSet === undefined) {
        state.currentLocation = [new BreadcrumbItem("", false, null, null, "#")];
      } else {
        state.currentLocation = locationSet;
      }
    },
    changeQueueData: (state, queueData): void => {
      state.queueData = queueData;
    },
    changeConfigurationDialogState: (state): void => {
      state.configurationDialogState = !state.configurationDialogState;
    },
    changeConfigurationInfo: (state, configModel): void => {
      state.configurationInfo = configModel;
    },
    changeActivityAutoRefresh: (state, autoRefresh): void => {
      state.activityAutoRefresh = autoRefresh;
    },
    changeRepoGridAutoRefresh: (state, autoRefresh): void => {
      state.repoGridAutoRefresh = autoRefresh;
    },
    changeFailedOperations: (state): void => {
      state.failedOperations = !state.failedOperations;
    },
    changeStatusDialogInfo: (state, logId: number): void => {
      state.statusDialogInfo = logId;
    },
    changeStatusDialogState: (state, value): void => {
      state.statusDialogState = value;
    },
    changeSelectedFolders: (state, selectedFolders: any[]): void => {
      state.selectedFolders = selectedFolders;
    },
    setActiveCompany: (state: AppState, company: Company): void => {
      state.activeCompany = company;
    },
    setHasAccess: (state: AppState, value: boolean): void => {
      state.hasAccess = value;
    },
    setCanAccessConfiguration: (state: AppState, value: boolean): void => {
      state.canAccessConfiguration = value;
    },
    updateUserCompanies: (state: AppState, companies: Company[]): void => {
      state.companies = companies;
      state.areCompaniesLoading = false;
    },
    refresh: (state): void => {
      state.currentLocation = [...state.currentLocation];
    },
    setHighlightedItem(state, highlightedItem) {
      state.highlightedItem = highlightedItem;
    }
  },
  actions: {
    async getQueueData({ state, commit }) {
      if (!state.activeCompany) {
        return;
      }
      const queueData = await GlobalHelper.http(`${config.resourceServer}/api/Main/GetQueues?companyId=${state.activeCompany?.Id}`, ErrorHelper.QueueError);
      commit("changeQueueData", queueData);
    },
    async changeActiveCompany({ commit }, company: Company) {
      if (!company) {
        return;
      }

      let activeCompany: Company | null;
      let configurationInfo = ConfigurationModel.FromObject({});
      let canAccessConfiguration = false;

      if (company.isExpired) {
        ErrorHelper.addSnackbarMessage(CommonText.companyAccessHasExpired, ResponseTypes.Error, "", true);
        activeCompany = null;
      } else {
        configurationInfo = await configurationService.getConfiguration(company.Id);
        canAccessConfiguration = await configurationService.getConfigurationPermission(company.Id);
        activeCompany = company;
      }

      commit("setActiveCompany", activeCompany);
      commit("changeLocationPlacement");
      commit("changeConfigurationInfo", configurationInfo);
      commit("setCanAccessConfiguration", canAccessConfiguration);

      router.push(RouterHelper.buildRoute(), () => {});
    },
    async getCompaniesForUser({ commit, dispatch }) {
      const companies = await companiesService.getCompanies();
      commit("updateUserCompanies", companies);
      commit("setHasAccess", !!companies && companies.length > 0 && !companies.every((x: Company) => x.isExpired));

      if (companies && companies.length === 1 && !companies[0].isExpired) {
        dispatch("changeActiveCompany", companies[0]);
      }
    }
  },
  getters: {
    currentLocationPath,
    deleteAvailable: (state: AppState) => {
      return !!state.selectedFolders[0];
    },
    downloadAvailable: (state: AppState) => {
      return !!state.selectedFolders[0];
    },
    renameAvailable: (state: AppState) => {
      const selectedItem: any = state.selectedFolders.length === 1 ? state.selectedFolders[0] : null;
      return selectedItem != null && !selectedItem.IsFolder;
    }
  }
});















































































import Vue from "vue";

import GlobalHelper from "@/helpers/globalHelper";
import { UploadStatuses } from "@/helpers/enums";
import UploadTreeItem from "@/components/Storage/Upload/types/uploadTreeItem";

export default Vue.extend({
  name: "UploadTreeView",
  props: {
    status: {
      type: Number as () => UploadStatuses
    },
    treeItems: {
      type: Array as () => UploadTreeItem[]
    },
    treeOpen: {
      type: Array as () => string[]
    }
  },
  data(): {
    GlobalHelper: GlobalHelper,
    UploadStatuses: typeof UploadStatuses,
    maxLength: number,
    } {
    return {
      GlobalHelper,
      UploadStatuses,
      maxLength: 30
    };
  }
});

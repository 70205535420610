export enum ProcessOptions {
    XMLStructure = "XML/Structure",
    ProcessPdfs = "Process PDF",
    VerifyChecksums = "Verify Checksums"
}

export enum UploadStatuses {
    Uploading,
    Success,
    Error
}

export enum ResponseTypes {
    Success = "success",
    Error = "error"
}

export enum UploadModes {
    Files,
    Folder
}




















import Vue from "vue";

export default Vue.extend({
  name: "UploadDragDropZone",
  props: {
    enabled: {
      type: Boolean
    }
  },
  data(): {
    dragActive: boolean,
    } {
    return {
      dragActive: false
    };
  },
  methods: {
    handleDragEnter(event: DragEvent) {
      if (!this.enabled) {
        return;
      }

      event.preventDefault();
      this.dragActive = true;
    },
    handleDragOver(event: DragEvent) {
      if (!this.enabled) {
        return;
      }

      event.preventDefault();
    },
    handleDragLeave(event: DragEvent) {
      if (!this.enabled) {
        return;
      }

      event.preventDefault();
      this.dragActive = false;
    },
    async handleDrop(event: DragEvent) {
      if (!this.enabled) {
        return;
      }

      event.preventDefault();

      this.dragActive = false;

      this.$emit("files-drop", await this.getFilesFromItems(event.dataTransfer?.items));
    },
    async getFilesFromItems(items: DataTransferItemList | undefined): Promise<File[]> {
      const files: File[] = [];

      if (items?.length) {
        const itemsAsArray: DataTransferItem[] = [];
        for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
          itemsAsArray.push(items[itemIndex]);
        }

        await Promise.all(itemsAsArray.map(async(item) => {
          const entry = item.webkitGetAsEntry?.();
          if (entry?.isDirectory) {
            const directoryFiles = await this.getFilesFromDirectory(entry, entry.name);
            files.push(...directoryFiles);
          } else if (entry?.isFile || (item.kind === "file" && item.getAsFile)) {
            const itemAsFile = item.getAsFile();
            if (itemAsFile) {
              files.push(itemAsFile);
            }
          }
        }));
      }

      return files;
    },
    // https://github.com/dropzone/dropzone/blob/main/src/dropzone.js
    async getFilesFromDirectory(directory: any, path: string): Promise<File[]> {
      const files: File[] = [];
      const directoryReader = directory.createReader();

      const readEntries = () => {
        return new Promise((resolve, reject) => {
          directoryReader.readEntries(async(entries: any) => {
            if (entries.length > 0) {
              for (const entry of entries) {
                if (entry.isFile) {
                  const file = await new Promise<File>((resolve, reject) => {
                    entry.file((entryFile: File) => {
                      resolve({
                        name: entryFile.name,
                        type: entryFile.type,
                        size: entryFile.size,
                        slice: entryFile.slice.bind(entryFile),
                        lastModified: entryFile.lastModified,
                        webkitRelativePath: `${path}/${entryFile.name}`
                      } as File);
                    }, reject);
                  });
                  files.push(file);
                } else if (entry.isDirectory) {
                  const directoryFiles = await this.getFilesFromDirectory(entry, `${path}/${entry.name}`);
                  files.push(...directoryFiles);
                }
              }

              // Recursively call readEntries() again, since browser only handle
              // the first 100 entries.
              // See: https://developer.mozilla.org/en-US/docs/Web/API/DirectoryReader#readEntries
              await readEntries();
            }
            resolve();
          }, reject);
        });
      };

      await readEntries();

      return files;
    }
  }
});































































import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import $ from "jquery";
import { sum, sumBy, chain, isArray } from "lodash";
import filesize from "filesize";
import { eachLimit, mapLimit } from "async";
import IdleManager from 'common-vue-components/helpers/IdleManager';
import ErrorHelper from "@/helpers/errorHelper";
import { ResponseTypes, UploadModes, UploadStatuses } from "@/helpers/enums";
import CompleteFileUploadRequest from "@/components/Storage/Upload/types/CompleteFileUploadRequest";
import UploadFileInfo from "@/components/Storage/Upload/types/UploadFileInfo";
import StartFileUploadRequest from "@/components/Storage/Upload/types/startFileUploadRequest";
import StartFileUploadResponse from "@/components/Storage/Upload/types/startFileUploadResponse";
import StartFileUploadSuccessResponse from "@/components/Storage/Upload/types/startFileUploadSuccessResponse";
import UploadTreeItem from "@/components/Storage/Upload/types/uploadTreeItem";
import UploadHelper from "@/components/Storage/Upload/helpers/uploadHelper";
import UploadTreeView from "@/components/Storage/Upload/UploadTreeView.vue";
import UploadDragDropZone from "@/components/Storage/Upload/UploadDragDropZone.vue";
import GlobalHelper from "@/helpers/globalHelper";

const FILE_CONCURRENT_UPLOADS_COUNT = 2;
const FILE_PART_CONCURRENT_UPLOADS_COUNT = 1;

export default Vue.extend({
  components: { UploadTreeView, UploadDragDropZone },
  computed: {
    ...mapGetters(["currentLocationPath"]),
    ...mapState(["currentLocation", "activeCompany"]),
    selectedEntities(): string {
      return this.files.map((x: UploadFileInfo) => x.file.name).join(", ");
    }
  },
  data(): {
    mode: UploadModes,
    dialog: boolean;
    status: UploadStatuses | undefined,
    files: UploadFileInfo[],
    filesSize: string,
    uploadProgress: number,
    treeOpen: string[],
    treeItems: UploadTreeItem[],
    flatTreeItems: UploadTreeItem[],
    activeEventInterval: number | undefined,
    refreshOnClose: boolean,
    UploadStatuses: typeof UploadStatuses,
    UploadModes: typeof UploadModes
    } {
    return {
      mode: UploadModes.Files,
      dialog: false,
      status: undefined,
      files: [],
      filesSize: "",
      uploadProgress: 0,
      treeOpen: [],
      treeItems: [],
      flatTreeItems: [],
      activeEventInterval: undefined,
      refreshOnClose: false,
      UploadStatuses,
      UploadModes
    };
  },
  watch: {
    files: function() {
      this.filesSize = filesize(sumBy(this.files, (fileInfo) => fileInfo.file.size), { standard: "jedec" });
    },
    mode: function() {
      this.files = [];
      this.treeItems = [];
      this.treeOpen = [];
    }
  },
  methods: {
    ...mapMutations(["refresh"]),
    handleInputChange(event: Event): void {
      const input = event.target as HTMLInputElement;
      this.addFiles(input.files);
      input.value = "";
    },
    selectFiles(mode: UploadModes) {
      const input = this.$refs.input as HTMLInputElement;
      if (mode === UploadModes.Folder) {
        input.setAttribute("allowdirs", "true");
        input.setAttribute("directory", "true");
        input.setAttribute("webkitdirectory", "true");
      } else {
        input.removeAttribute("allowdirs");
        input.removeAttribute("directory");
        input.removeAttribute("webkitdirectory");
      }
      input.click();
    },
    addFiles(files: FileList | File[] | null) {
      let filesAsArray: File[] = [];

      if (files instanceof FileList) {
        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
          filesAsArray.push(files[fileIndex]);
        }
      }

      if (isArray(files)) {
        filesAsArray = files;
      }

      if (!filesAsArray.length) {
        return;
      }

      const isFirstFiles = !this.files.length;

      this.status = undefined;
      this.files = chain(filesAsArray)
        .map((file) => {
          const filePath = [
            this.currentLocationPath ? `${this.currentLocationPath}/` : "",
            file.webkitRelativePath ? file.webkitRelativePath : file.name
          ].join("");

          const fileInfo: UploadFileInfo = {
            file,
            filePath,
            uploadStatus: undefined,
            uploadProgress: 0,
            uploadPartProgresses: []
          };

          return fileInfo;
        })
        // union with already added files
        .concat(this.files)
        // replacing duplicates by file path
        .unionBy(file => file.filePath)
        .value();

      this.treeItems = UploadHelper.buildTreeItems(this.files);

      if (isFirstFiles && !!this.treeItems[0].folderInfo) {
        this.treeOpen = [this.treeItems[0].path];
      }
    },
    async uploadFiles() {
      if (!this.activeCompany) {
        return;
      }

      if (!this.files.length) {
        return;
      }

      this.flatTreeItems = UploadHelper.getFlatItems(this.treeItems);
      this.status = UploadStatuses.Uploading;
      this.updateUploadProgress();

      !this.activeEventInterval && (this.activeEventInterval = setInterval(() => IdleManager.handleAction(), 5000));

      const startFileUploadRequests = this.files
        .map<StartFileUploadRequest>(fileInfo => ({
          path: fileInfo.filePath,
          size: fileInfo.file.size
        }));

      try {
        const startFileUploadResponses: StartFileUploadResponse[] = await $.ajax({
          url: "api/Storage/StartFilesUpload",
          method: "POST",
          headers: GlobalHelper.getHeaders(),
          data: {
            companyId: this.activeCompany.Id,
            files: startFileUploadRequests,
            currentLocationPath: this.currentLocationPath
          }
        });

        startFileUploadResponses.forEach((startFileUploadResponse, fileIndex) => {
          if (startFileUploadResponse.ResponseType === ResponseTypes.Error) {
            const file = this.files[fileIndex];

            file.uploadStatus = UploadStatuses.Error;
            file.uploadErrorMessage = startFileUploadResponse.ErrorMessage;
            this.setErrorsForFolders(file);
          }
        });

        await eachLimit(this.files.filter(file => file.uploadStatus !== UploadStatuses.Error), FILE_CONCURRENT_UPLOADS_COUNT, (file, callback) => {
          const fileIndex = this.files.indexOf(file);
          this.uploadFile(this.activeCompany.Id, file, startFileUploadResponses[fileIndex] as StartFileUploadSuccessResponse)
            .then(() => callback(), error => callback(error));
        });

        this.status = this.files.some(file => file.uploadStatus === UploadStatuses.Error) ? UploadStatuses.Error : UploadStatuses.Success;
      } catch (error) {
        this.status = UploadStatuses.Error;
        ErrorHelper.addSnackbarMessage("The file(s) upload failed", ResponseTypes.Error, error.responseText, true);
      }

      if (this.status === UploadStatuses.Success) {
        ErrorHelper.addSnackbarMessage("The files are uploaded successfully", ResponseTypes.Success);
      }

      this.files = [];
      this.refreshOnClose = true;
    },
    async uploadFile(companyId: number, fileInfo: UploadFileInfo, startFileUploadResponse: StartFileUploadSuccessResponse) {
      fileInfo.uploadStatus = UploadStatuses.Uploading;

      fileInfo.uploadFileName = startFileUploadResponse.FileName;
      fileInfo.uploadFilePath = startFileUploadResponse.FilePath;

      try {
        fileInfo.uploadPartProgresses = Array.from({ length: startFileUploadResponse.PartUrls.length }, () => 0);

        const uploadedPartETags: string[] = await mapLimit(
          startFileUploadResponse.PartUrls,
          FILE_PART_CONCURRENT_UPLOADS_COUNT,
          async(partUrl, callback): Promise<void> => {
            const partIndex = startFileUploadResponse.PartUrls.indexOf(partUrl);
            const start = partIndex * startFileUploadResponse.PartSize;
            const end = (partIndex + 1) * startFileUploadResponse.PartSize;
            const blob = partIndex < (startFileUploadResponse.PartUrls.length - 1)
              ? fileInfo.file.slice(start, end)
              : fileInfo.file.slice(start);

            let etag: string;
            $.ajax({
              type: "PUT",
              url: partUrl,
              data: blob,
              processData: false,
              contentType: false,
              xhr: () => {
                const xhr = new window.XMLHttpRequest();
                xhr.upload.onprogress = (event) => {
                  fileInfo.uploadPartProgresses[partIndex] = event.loaded;
                  fileInfo.uploadProgress = UploadHelper.calculateProgress(
                    sum(fileInfo.uploadPartProgresses),
                    fileInfo.file.size
                  );

                  this.updateUploadProgress(fileInfo);
                };
                return xhr;
              },
              complete: function(jqXHR) {
                etag = jqXHR.getResponseHeader("etag")!;
              }
            })
              .then(() => {
                callback(null, etag);
              })
              .fail(err => {
                callback(err);
              });
          });

        const completeFileUploadRequest: CompleteFileUploadRequest = {
          companyId: companyId,
          path: fileInfo.uploadFilePath,
          uploadId: startFileUploadResponse.UploadId,
          partETags: uploadedPartETags
        };

        await $.ajax({
          url: "api/Storage/CompleteFileUpload",
          method: "POST",
          headers: GlobalHelper.getHeaders(),
          data: completeFileUploadRequest
        });

        fileInfo.uploadProgress = 100;
        fileInfo.uploadStatus = UploadStatuses.Success;

        this.updateUploadProgress(fileInfo);
      } catch (error) {
        fileInfo.uploadStatus = UploadStatuses.Error;
        fileInfo.uploadErrorMessage = "The file upload failed";
        this.setErrorsForFolders(fileInfo);
        throw error;
      }
    },
    updateUploadProgress(fileInfo?: UploadFileInfo) {
      const uploadedSize = sum(this.files.flatMap(({ uploadPartProgresses }) => uploadPartProgresses));
      const totalSize = sumBy(this.files, ({ file }) => file.size);

      this.uploadProgress = UploadHelper.calculateProgress(uploadedSize, totalSize);

      if (fileInfo) {
        // update folders upload progress
        this.flatTreeItems
          .forEach((treeItem: UploadTreeItem) => {
            if (!treeItem.folderInfo || !fileInfo.file.webkitRelativePath.startsWith(treeItem.path + "/")) {
              return;
            }

            const folderFileInfos = this.flatTreeItems
              .filter((x: UploadTreeItem) => x.fileInfo && x.fileInfo.file.webkitRelativePath.startsWith(treeItem.path + "/"))
              .map(x => x.fileInfo!);
            const allFilesUploaded = !folderFileInfos.some(fileInfo => fileInfo.uploadProgress !== 100);

            let uploadProgress: number;
            // all files uploaded check is needed to support empty files
            if (allFilesUploaded) {
              uploadProgress = 100;
            } else {
              const current = sum(folderFileInfos.map(fileInfo => sum(fileInfo.uploadPartProgresses)));
              const total = sum(folderFileInfos.map(fileInfo => fileInfo.file.size));

              uploadProgress = UploadHelper.calculateProgress(current, total);
            }

            treeItem.folderInfo.uploadProgress = uploadProgress;
          });
      }
    },
    removeTreeItem(item: UploadTreeItem): boolean {
      const removeItem = (items: UploadTreeItem[], item: UploadTreeItem): boolean => {
        const itemIndex = items.indexOf(item);
        if (itemIndex !== -1) {
          this.$delete(items, itemIndex);

          this.files = this.files.filter(
            ({ file }) => file.webkitRelativePath && item.folderInfo
              ? !file.webkitRelativePath.startsWith(item.path)
              : file.name !== item.name);
          return true;
        }

        for (const eachItem of items) {
          if (removeItem(eachItem.children, item)) {
            return true;
          }
        }

        return false;
      };

      removeItem(this.treeItems, item);

      return false;
    },
    openDialog() {
      this.files = [];
      this.treeItems = [];
      this.dialog = true;
      this.refreshOnClose = false;
    },
    closeDialog() {
      this.dialog = false;
      this.status = undefined;

      clearInterval(this.activeEventInterval);
      this.activeEventInterval = undefined;

      if (this.refreshOnClose) {
        this.refresh();
      }
    },
    setErrorsForFolders(fileInfo: UploadFileInfo) {
      this.flatTreeItems.filter((x: UploadTreeItem) => !x.fileInfo && fileInfo.file.webkitRelativePath.startsWith(x.path + "/"))
        .forEach((x: UploadTreeItem) => {
          if (x.folderInfo) {
            x.folderInfo.uploadStatus = UploadStatuses.Error;
            x.folderInfo.uploadErrorMessage = "One or more file uploads have failed";
          }
        });
    }
  }
});

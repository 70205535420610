import UploadTreeItem from "@/components/Storage/Upload/types/uploadTreeItem";
import UploadFileInfo from "@/components/Storage/Upload/types/uploadFileInfo";
import { orderBy } from "lodash";
import filesize from "filesize";
import { UploadModes } from "@/helpers/enums";

export default class UploadHelper {
  public static calculateProgress(current: number, total: number): number {
    return Math.round(current / total * 100);
  }

  public static sortTreeItems(items: UploadTreeItem[]) {
    const sort = (items: UploadTreeItem[]) => {
      return orderBy(
        items,
        [(x) => !!x.folderInfo, (x) => x.name.toLowerCase()],
        ["desc", "asc"]);
    };

    items.forEach(item => item.children && (item.children = sort(item.children)));
    return sort(items);
  }

  public static buildTreeItems(files: UploadFileInfo[]): UploadTreeItem[] {
    const treeItems: UploadTreeItem[] = [];

    for (const fileInfo of files) {
      const { file } = fileInfo;
      const relativePath = file.webkitRelativePath;
      const fileParents = relativePath.split("/").slice(0, -1);

      let parentTreeItems: UploadTreeItem[] = treeItems;

      for (let parentIndex = 0; parentIndex < fileParents.length; parentIndex++) {
        const parentName = fileParents[parentIndex];

        let currentFolderItem = parentTreeItems.find(item => item.folderInfo && item.name === parentName);
        if (!currentFolderItem) {
          const currentFolderPath = fileParents.slice(0, parentIndex + 1);
          currentFolderItem = {
            name: parentName,
            path: currentFolderPath.join("/"),
            children: [],
            folderInfo: { uploadProgress: 0, uploadStatus: undefined }
          };
          parentTreeItems.push(currentFolderItem);
        }
        parentTreeItems = currentFolderItem.children;
      }

      parentTreeItems.push({
        name: file.name,
        path: relativePath || file.name,
        children: [],
        size: filesize(file.size, { standard: "jedec" }),
        fileInfo
      });
    }

    return this.sortTreeItems(treeItems);
  }

  public static getFlatItems(items: UploadTreeItem[]): UploadTreeItem[] {
    let childItems: UploadTreeItem[] = [];
    const flattenMembers = items.map(treeItem => {
      if (treeItem.children?.length) {
        childItems = [...childItems, ...treeItem.children];
      }
      return treeItem;
    });

    return flattenMembers.concat(childItems.length ? this.getFlatItems(childItems) : childItems);
  }
}

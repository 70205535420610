var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root"},[(!_vm.treeItems.length)?_c('div',{staticClass:"mx-auto"},[_vm._v("Files list is empty")]):_c('v-treeview',{staticClass:"tree",attrs:{"items":_vm.treeItems,"open":_vm.treeOpen,"item-key":"path"},on:{"update:open":function($event){return _vm.$emit('update:open', $event)}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.children.length)?_c('v-icon',[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_c('v-icon',[_vm._v(_vm._s(_vm.GlobalHelper.getIconType(item.name)))])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-grow-1 align-center justify-space-between"},[_c('div',{staticClass:"tree-item-name"},[_c('v-tooltip',{attrs:{"top":"","disabled":item.name.length <= _vm.maxLength},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])]),((item.fileInfo || {}).uploadFileName && item.fileInfo.uploadFileName !== item.name)?_c('v-tooltip',{attrs:{"top":"","disabled":(item.fileInfo.uploadFileName || {}).length <= _vm.maxLength},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex caption"},'div',attrs,false),on),[_vm._v(" ( "),_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.fileInfo.uploadFileName))]),_vm._v(" ) ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.fileInfo.uploadFileName))])]):_vm._e(),(item.folderInfo && item.name !== item.name.toLowerCase())?_c('v-tooltip',{attrs:{"top":"","disabled":item.name.length <= _vm.maxLength},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex caption"},'div',attrs,false),on),[_vm._v(" ( "),_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.name.toLowerCase()))]),_vm._v(" ) ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name.toLowerCase()))])]):_vm._e()],1),(item.size)?_c('span',{staticClass:"caption ml-2"},[_vm._v("("+_vm._s(item.size)+")")]):_vm._e(),_c('v-progress-linear',{staticClass:"tree-item-progress",attrs:{"rounded":"","height":"8","background-color":"grey lighten-1","color":"green","value":item.fileInfo ? item.fileInfo.uploadProgress : item.folderInfo.uploadProgress}}),(_vm.status === undefined)?_c('v-btn',{staticClass:"tree-item-button",attrs:{"small":"","icon":""},on:{"click":function () { return _vm.$emit('remove-tree-item', item); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1):_vm._e(),((item.fileInfo || item.folderInfo || {}).uploadStatus === _vm.UploadStatuses.Error)?_c('v-tooltip',{staticClass:"flex-shrink-0",attrs:{"top":"","disabled":!(item.fileInfo || item.folderInfo || {}).uploadErrorMessage},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.fileInfo ? 'red' : 'warning'}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.fileInfo ? "mdi-alert" : "mdi-alert-outline")+" ")])]}}],null,true)},[_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s((item.fileInfo || item.folderInfo || {}).uploadErrorMessage))])]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }